<template>
  <div class="py-0 ml-3">
    <v-alert
      border="left"
      colored-border
      color="red"
      elevation="1"
      dense
      prominent
      icon="mdi-calendar"
    >
      <v-row align="center" justify="end" class="py-3">
        <v-col class="grow font-weight-bold">
          <div class="float-left mr-1">
            {{ $t("next-meeting") }}
          </div>
          <div v-if="nextMeetingResponse">
            {{ getNextMeeting(nextMeetingResponse) | formatDate }}
          </div>
          <div v-else>
            <v-progress-circular indeterminate size="24" class="ml-5" />
          </div>
        </v-col>
        <v-col class="shrink">
          <v-btn
            to="/group/location"
            exact
            class="float-right"
            outlined
            color="red"
            >{{ $t("location") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-alert>

    <v-alert
      border="left"
      colored-border
      color="orange"
      elevation="1"
      dense
      prominent
      icon="mdi-email"
    >
      <v-row align="center" justify="end" class="py-3">
        <v-col class="grow">{{ $t("get-in-contact") }}</v-col>
        <v-col class="shrink">
          <v-btn
            to="/contact"
            exact
            class="float-right"
            outlined
            color="orange"
            >{{ $t("mail") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-alert>

    <v-alert
      border="left"
      colored-border
      color="amber"
      elevation="1"
      dense
      prominent
      icon="mdi-account-group"
    >
      <v-row align="center" justify="end" class="py-3">
        <v-col class="grow">{{ $t("association") }}</v-col>
        <v-col class="shrink">
          <v-btn
            to="/association"
            exact
            class="float-right"
            outlined
            color="amber"
            >{{ $t("overview") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-alert>

    <v-alert
      border="left"
      colored-border
      color="green"
      elevation="1"
      dense
      prominent
      icon="mdi-calendar-check"
    >
      <v-row align="center" justify="end" class="py-3">
        <v-col class="grow">{{ $t("events") }}</v-col>
        <v-col class="shrink">
          <v-btn
            to="/calendar"
            exact
            class="float-right"
            outlined
            color="green"
            >{{ $t("take-part") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-alert>

    <v-alert
      border="left"
      colored-border
      color="blue"
      elevation="1"
      dense
      prominent
      icon="mdi-file-document"
    >
      <v-row align="center" justify="end" class="py-3">
        <v-col class="grow"> {{ $t("info-material") }}</v-col>
        <v-col class="shrink">
          <v-btn
            to="/documents"
            exact
            class="float-right"
            outlined
            color="blue"
            >{{ $t("documents") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-alert>

    <v-alert
      border="left"
      colored-border
      color="purple"
      elevation="1"
      dense
      prominent
      icon="mdi-history"
    >
      <v-row align="center" justify="end" class="py-3">
        <v-col class="grow"> {{ $t("history") }}</v-col>
        <v-col class="shrink">
          <v-btn
            to="/association/history"
            exact
            class="float-right"
            outlined
            color="purple"
            >{{ $t("timeline") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>
<script>
export default {
  props: {
    nextMeetingResponse: {
      required: true
    }
  },
  data: () => ({}),
  methods: {
    getNextMeeting(response) {
      if (response.status != 200) {
        return this.$t("communication-error");
      }
      if (response.data.data.start) {
        return response.data.data.start;
      }
      return this.$t("unknown-date");
    }
  }
};
</script>
<style scoped>
.col {
  font-weight: bold !important;
}
</style>
