import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/impress",
    name: "impress",
    component: () => import("../views/Impress.vue")
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/Contact.vue")
  },
  {
    path: "/disorders",
    name: "disorders",
    component: () => import("../views/disorders/General.vue")
  },
  {
    path: "/disorders/anorexia",
    name: "anorexia",
    component: () => import("../views/disorders/Anorexia.vue")
  },
  {
    path: "/disorders/bulimia",
    name: "bulimia",
    component: () => import("../views/disorders/Bulimia.vue")
  },
  {
    path: "/disorders/binge-eating",
    name: "binge-eating",
    component: () => import("../views/disorders/BingeEating.vue")
  },
  {
    path: "/disorders/adipositas",
    name: "adipositas",
    component: () => import("../views/disorders/Adipositas.vue")
  },
  {
    path: "/disorders/orthorexia",
    name: "orthorexia",
    component: () => import("../views/disorders/Orthorexia.vue")
  },
  {
    path: "/disorders/biggerexia",
    name: "biggerexia",
    component: () => import("../views/disorders/Biggerexia.vue")
  },
  {
    path: "/disorders/night-eating-syndrom",
    name: "night-eating-syndrom",
    component: () => import("../views/disorders/NightEatingSyndrom.vue")
  },
  {
    path: "/disorders/pica-syndrom",
    name: "pica-syndrom",
    component: () => import("../views/disorders/PicaSyndrom.vue")
  },
  {
    path: "/disorders/chewing-and-spitting",
    name: "chewing-and-spitting",
    component: () => import("../views/disorders/ChewingAndSpitting.vue")
  },
  {
    path: "/disorders/relatives",
    name: "relatives",
    component: () => import("../views/disorders/Relatives.vue")
  },
  {
    path: "/group",
    name: "group",
    component: () => import("../views/group/Concept.vue")
  },
  {
    path: "/group/location",
    name: "location",
    component: () => import("../views/group/Location.vue")
  },
  {
    path: "/group/rules",
    name: "rules",
    component: () => import("../views/group/Rules.vue")
  },
  {
    path: "/group/godparent",
    name: "godparent",
    component: () => import("../views/group/Godparent.vue")
  },
  {
    path: "/association",
    name: "association",
    component: () => import("../views/association/Overview.vue")
  },
  {
    path: "/association/membership",
    name: "membership",
    component: () => import("../views/association/Membership.vue")
  },
  {
    path: "/association/support",
    name: "support",
    component: () => import("../views/association/Support.vue")
  },
  {
    path: "/association/history",
    name: "history",
    component: () => import("../views/association/History.vue")
  },
  {
    path: "/offerings/books",
    name: "books",
    component: () => import("../views/offerings/Books.vue")
  },
  {
    path: "/offerings/events",
    name: "events",
    component: () => import("../views/offerings/Events.vue")
  },
  {
    path: "/offerings/lecture",
    name: "lecture",
    component: () => import("../views/offerings/Lecture.vue")
  },
  {
    path: "/calendar",
    name: "calendar",
    component: () => import("../views/Calendar.vue")
  },
  {
    path: "/help",
    name: "help",
    component: () => import("../views/help/Help.vue")
  },
  {
    path: "/help/medical",
    name: "medical",
    component: () => import("../views/help/Medical.vue")
  },
  {
    path: "/help/doctors",
    name: "doctors",
    component: () => import("../views/help/Doctors.vue")
  },
  {
    path: "/cooperations",
    name: "cooperations",
    component: () => import("../views/Cooperations.vue")
  },
  {
    path: "/documents",
    name: "documents",
    component: () => import("../views/Documents.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes
});

export default router;
