import "@babel/polyfill";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import vuetify from "./plugins/vuetify";
import moment from "moment";

Vue.config.productionTip = false;
Vue.filter("formatDate", function(value) {
  if (value) {
    let date = moment(String(value)).format("DD.MM.YYYY");
    if (date != "Invalid date") {
      return date;
    }
    return value;
  }
});
Vue.filter("formatTime", function(value) {
  if (value) {
    let date = moment(String(value)).format("HH:mm");
    if (date != "Invalid date") {
      return date;
    }
    return value;
  }
});

new Vue({
  router,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
