<template>
  <v-container>
    <v-alert
      v-if="$vuetify.breakpoint.smAndDown"
      border="left"
      colored-border
      color="red"
      elevation="1"
      prominent
      icon="mdi-calendar"
    >
      <v-row align="center" justify="end">
        <v-col class="grow font-weight-bold">
          <div class="float-left mr-1">
            {{ $t("next-meeting") }}
          </div>
          <div v-if="nextMeetingResponse">
            {{ getNextMeeting(nextMeetingResponse) | formatDate }}
          </div>
          <div v-else>
            <v-progress-circular indeterminate size="24" class="ml-5" />
          </div>
        </v-col>
        <v-col class="shrink">
          <v-btn
            class="float-right"
            to="/group/location"
            exact
            outlined
            color="red"
            >{{ $t("location") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-alert>

    <v-row justify="center">
      <v-col>
        <v-sheet class="pa-8">
          <h1 class="mb-2 display-2 text-center">{{ $t("welcome-title") }}</h1>
          <p class="display-1 text-center">{{ $t("welcome-text-1") }}</p>
          <p class="text-center">{{ $t("welcome-text-2") }}</p>
          <div class="text-center">
            <p class="text-center">{{ $t("welcome-text-3") }}</p>
            <v-btn color="accent" to="/help" large>
              <v-icon class="mr-2" large>mdi-hospital-box-outline</v-icon>
              {{ $t("welcome-help-button") }}
            </v-btn>
          </div>

          <h2 class="display-1 mb-2 mt-8 accent--text text-uppercase">
            {{ $t("welcome-headline-1") }}
          </h2>
          <p>
            {{ $t("welcome-content-1") }}
          </p>
          <h2 class="display-1 mb-2 mt-8 accent--text text-uppercase">
            {{ $t("welcome-headline-2") }}
          </h2>
          <p class="mb-8">
            {{ $t("welcome-content-2") }}
          </p>
          <!-- Group Paragraph -->
          <div
            v-if="$vuetify.breakpoint.smAndUp"
            class="image-container image-left"
          >
            <v-img
              src="@/assets/group.png"
              min-height="250px"
              min-width="250px"
            />
          </div>
          <p class="mb-8">
            {{ $t("welcome-content-3") }}
            <router-link class="link" to="/group">{{
              $t("here.")
            }}</router-link>
          </p>
          <div
            v-if="$vuetify.breakpoint.xsOnly"
            class="image-container mx-auto mb-8"
          >
            <v-img
              src="@/assets/group.png"
              min-height="250px"
              min-width="250px"
            />
          </div>
          <!-- Godparent Paragraph -->
          <div
            v-if="$vuetify.breakpoint.smAndUp"
            class="image-container image-right"
          >
            <v-img
              src="@/assets/handshake.png"
              min-height="250px"
              min-width="250px"
            />
          </div>
          <p class="mb-8">
            {{ $t("welcome-content-4-part-1") }}
            <router-link class="link" to="/group/godparent">{{
              $t("here")
            }}</router-link>
            {{ $t("welcome-content-4-part-2") }}
          </p>
          <div
            v-if="$vuetify.breakpoint.xsOnly"
            class="image-container mx-auto mb-8"
          >
            <v-img
              src="@/assets/handshake.png"
              min-height="250px"
              min-width="250px"
            />
          </div>
          <!-- Cook Paragraph -->
          <div
            v-if="$vuetify.breakpoint.smAndUp"
            class="image-container image-left"
          >
            <v-img
              src="@/assets/event.png"
              min-height="250px"
              min-width="250px"
            />
          </div>
          <p class="mb-8">
            {{ $t("welcome-content-5-part-1") }}
            <router-link class="link" to="/offerings/events">{{
              $t("here.")
            }}</router-link>
            {{ $t("welcome-content-5-part-2") }}
            <router-link class="link" to="/calendar">{{
              $t("welcome-content-5-link")
            }}</router-link>
            {{ $t("welcome-content-5-part-3") }}
          </p>
          <div
            v-if="$vuetify.breakpoint.xsOnly"
            class="image-container mx-auto mb-8"
          >
            <v-img
              src="@/assets/event.png"
              min-height="250px"
              min-width="250px"
            />
          </div>
          <!-- Lecture Paragraph -->
          <div
            v-if="$vuetify.breakpoint.smAndUp"
            class="image-container image-right"
          >
            <v-img
              src="@/assets/lecture.png"
              min-height="250px"
              min-width="250px"
            />
          </div>
          <p class="mb-8">
            {{ $t("welcome-content-6") }}
            <router-link class="link" to="/offerings/lecture">{{
              $t("here.")
            }}</router-link>
          </p>
          <div
            v-if="$vuetify.breakpoint.xsOnly"
            class="image-container mx-auto mb-8"
          >
            <v-img
              src="@/assets/lecture.png"
              min-height="250px"
              min-width="250px"
            />
          </div>
          <!-- Book Paragraph -->
          <div
            v-if="$vuetify.breakpoint.smAndUp"
            class="image-container image-left"
          >
            <v-img
              src="@/assets/books.png"
              min-height="250px"
              min-width="250px"
            />
          </div>
          <p class="mb-8">
            {{ $t("welcome-content-7-part-1") }}
            <router-link class="link" to="/offerings/books">{{
              $t("here")
            }}</router-link>
            {{ $t("welcome-content-7-part-2") }}
          </p>
          <div
            v-if="$vuetify.breakpoint.xsOnly"
            class="image-container mx-auto mb-8"
          >
            <v-img
              src="@/assets/books.png"
              min-height="250px"
              min-width="250px"
            />
          </div>
        </v-sheet>
      </v-col>
      <v-col v-if="!$vuetify.breakpoint.smAndDown" xl="4" lg="4" md="4">
        <QuickLinkList :nextMeetingResponse="nextMeetingResponse" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import QuickLinkList from "@/components/QuickLinkList.vue";

export default {
  name: "home",
  components: {
    QuickLinkList
  },
  data: () => ({
    nextMeetingResponse: null
  }),
  mounted() {
    axios
      .get("https://api.bauchgefuehle-giessen.de/api/events/next-group")
      .then(response => (this.nextMeetingResponse = response))
      .catch(error => (this.nextMeetingResponse = error));
  },
  methods: {
    getNextMeeting(response) {
      if (response.status != 200) {
        return this.$t("communication-error");
      }
      if (response.data.data.start) {
        return response.data.data.start;
      }
      return this.$t("unknown-date");
    }
  }
};
</script>
<style scoped>
p {
  text-align: justify;
}
.link {
  color: #ffc107 !important;
  font-weight: bold;
  text-decoration: none;
}
.image-container {
  position: relative;
  width: 250px;
  height: 250px;
}
.image-container.image-right {
  position: relative;
  float: right;
  margin-bottom: 1rem;
  margin-top: 1rem;
  margin-left: 2rem;
}
.image-container.image-left {
  position: relative;
  float: left;
  margin-bottom: 1rem;
  margin-top: 1rem;
  margin-right: 2rem;
}
</style>
