<template>
  <v-app id="app">
    <!-- Sidebar -->
    <v-navigation-drawer
      id="nav"
      v-model="drawer"
      app
      clipped
      color="accent"
      :expand-on-hover="expand"
      :mini-variant="expand"
    >
      <!-- List -->
      <v-list dense>
        <!-- Home -->
        <v-list-item exact-active-class="custom-active" to="/" exact>
          <v-list-item-action>
            <v-icon>mdi-bee-flower</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("home") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <!-- Nav Items -->
        <div v-for="nav in navigations" :key="nav.slug">
          <!-- Dropdown group when subnavigations are given -->
          <v-list-group
            v-if="nav.subnavigations != null"
            :prepend-icon="nav.icon"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-title>{{ $t(nav.slug) }}</v-list-item-title>
            </template>
            <v-list-item
              v-for="subnav in nav.subnavigations"
              :key="subnav.slug"
              :to="subnav.url"
              exact
            >
              <v-list-item-content>
                <v-list-item-title>{{ $t(subnav.slug) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <!-- Normal item when no subnavigations are given -->
          <v-list-item v-else :to="nav.url" exact>
            <v-list-item-action>
              <v-icon>{{ nav.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t(nav.slug) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
      <template v-if="this.$vuetify.breakpoint.lgAndUp" v-slot:append>
        <div>
          <v-btn
            block
            v-if="expand"
            depressed
            color="transparent"
            @click="customExpand = false"
          >
            <v-icon>mdi-ray-start-arrow</v-icon>
          </v-btn>
          <v-btn
            block
            v-else
            depressed
            color="transparent"
            @click="customExpand = true"
          >
            <v-icon>mdi-ray-end-arrow</v-icon>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <!-- Header -->
    <v-app-bar
      id="app-bar"
      app
      clipped-left
      fixed
      shrink-on-scroll
      prominent
      @click="redirectToHome"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title v-if="$vuetify.breakpoint.lgAndUp">
        {{ $t("brand-ev") }}
      </v-toolbar-title>
    </v-app-bar>

    <!-- Content -->
    <v-main class="mt-3">
      <Alerts />
      <router-view />
    </v-main>

    <!-- Footer -->
    <PageFooter />
  </v-app>
</template>

<script>
import Alerts from "@/components/Alerts.vue";
import PageFooter from "@/components/PageFooter.vue";

export default {
  name: "App",
  components: {
    Alerts,
    PageFooter
  },
  data: () => ({
    drawer: false,
    customExpand: null,
    systemExpand: false,
    navigations: [
      {
        slug: "disorders",
        icon: "mdi-brain",
        subnavigations: [
          {
            slug: "general",
            url: "/disorders"
          },
          {
            slug: "anorexia",
            url: "/disorders/anorexia"
          },
          {
            slug: "bulimia",
            url: "/disorders/bulimia"
          },
          {
            slug: "binge-eating",
            url: "/disorders/binge-eating"
          },
          {
            slug: "adipositas",
            url: "/disorders/adipositas"
          },
          {
            slug: "orthorexia",
            url: "/disorders/orthorexia"
          },
          {
            slug: "biggerexia",
            url: "/disorders/biggerexia"
          },
          {
            slug: "night-eating-syndrom",
            url: "/disorders/night-eating-syndrom"
          },
          {
            slug: "pica-syndrom",
            url: "/disorders/pica-syndrom"
          },
          {
            slug: "chewing-and-spitting",
            url: "/disorders/chewing-and-spitting"
          },
          {
            slug: "relatives",
            url: "/disorders/relatives"
          }
        ]
      },
      {
        slug: "group",
        icon: "mdi-account-group-outline",
        subnavigations: [
          {
            slug: "concept",
            url: "/group"
          },
          {
            slug: "location",
            url: "/group/location"
          },
          {
            slug: "rules",
            url: "/group/rules"
          },
          {
            slug: "godparent-system",
            url: "/group/godparent"
          }
        ]
      },
      {
        slug: "association",
        icon: "mdi-account-multiple-plus-outline",
        subnavigations: [
          {
            slug: "overview",
            url: "/association"
          },
          {
            slug: "membership",
            url: "/association/membership"
          },
          {
            slug: "support",
            url: "/association/support"
          },
          {
            slug: "history",
            url: "/association/history"
          }
        ]
      },
      {
        slug: "offerings",
        icon: "mdi-book-open-page-variant-outline",
        subnavigations: [
          {
            slug: "offerings-books",
            url: "/offerings/books"
          },
          {
            slug: "offerings-events",
            url: "/offerings/events"
          },
          {
            slug: "offerings-lecture",
            url: "/offerings/lecture"
          }
        ]
      },
      {
        slug: "help",
        icon: "mdi-hospital-box-outline",
        subnavigations: [
          {
            slug: "acute-contacts",
            url: "/help"
          },
          {
            slug: "medical",
            url: "/help/medical"
          },
          {
            slug: "doctors",
            url: "/help/doctors"
          }
        ]
      },
      {
        slug: "calendar",
        url: "/calendar",
        icon: "mdi-calendar-multiple"
      },
      {
        slug: "cooperations",
        url: "/cooperations",
        icon: "mdi-bank-outline"
      },
      {
        slug: "contact",
        url: "/contact",
        icon: "mdi-card-account-mail-outline"
      },
      {
        slug: "documents",
        url: "/documents",
        icon: "mdi-download-outline"
      }
    ]
  }),
  computed: {
    expand: function() {
      if (this.customExpand != null) {
        return this.customExpand;
      } else {
        return this.systemExpand;
      }
    }
  },
  mounted: function() {
    this.drawer = this.$vuetify.breakpoint.lgAndUp;
    this.setExpandAccordingToScreenSize();
    window.addEventListener("resize", this.setExpandAccordingToScreenSize);
  },
  beforeDestroy: function() {
    window.removeEventListener("resize", this.setExpandAccordingToScreenSize);
  },
  methods: {
    redirectToHome: function() {
      this.$router.push("/");
    },
    setExpandAccordingToScreenSize: function() {
      let windowWidth = document.documentElement.clientWidth;
      this.systemExpand = windowWidth >= 1264 && windowWidth <= 1450;
    }
  }
};
</script>

<style scoped>
#app {
  background-color: #fafafa;
}
#app-bar {
  background-image: radial-gradient(
      ellipse farthest-corner,
      transparent 40%,
      rgba(224, 224, 224, 0.3) 60%,
      rgba(224, 224, 224, 1) 90%
    ),
    url("~@/assets/logo.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
}
#app-bar:hover {
  cursor: pointer;
}

#nav {
  background-image: linear-gradient(transparent, white 60%);
}
</style>
