<template>
  <v-footer color="secondary" padless>
    <v-row justify="space-between" no-gutters>
      <v-spacer></v-spacer>
      <v-col align-self="center">
        <v-row justify="center" no-gutters>
          <v-btn
            v-for="footerLink in footerLinks"
            :key="footerLink.slug"
            text
            rounded
            class="my-2"
            :to="footerLink.url"
            exact
          >
            {{ $t(footerLink.slug) }}
          </v-btn>
        </v-row>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndUp" align-self="center">
        <strong class="float-right mr-3">
          &copy; {{ new Date().getFullYear() }} — {{ $t("brand-ev") }}
        </strong>
      </v-col>
      <v-spacer v-if="$vuetify.breakpoint.smAndDown"></v-spacer>
      <v-col
        v-if="$vuetify.breakpoint.smAndDown"
        cols="12"
        align-self="center"
        class="text-center"
      >
        <strong>
          &copy; {{ new Date().getFullYear() }} — {{ $t("brand-ev") }}
        </strong>
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
export default {
  data: () => ({
    footerLinks: [
      { slug: "impress", url: "/impress" },
      { slug: "contact", url: "/contact" }
    ]
  })
};
</script>
