import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    light: true,
    dark: false,
    themes: {
      light: {
        primary: "#212121",
        secondary: "#E0E0E0",
        accent: "#FFC107",
        error: "#F44336",
        warning: "#FF9800",
        info: "#2196F3",
        success: "#4CAF50"
      },
      dark: {
        primary: "#212121",
        secondary: "#E0E0E0",
        accent: "#FFC107",
        error: "#F44336",
        warning: "#FF9800",
        info: "#2196F3",
        success: "#4CAF50"
      }
    }
  }
});
